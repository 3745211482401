<template>
<a-layout>
    <a-layout-content v-if="_mobile" style="overflow-x:hidden">
        <div style="overflow-x:hidden">
            <a-row class="home">
                <a-col :span="24" style="text-align:center">
                    <img style="margin-top:60px;width:80%;height:100%;" src="@/assets/images/v4-icon/mtitle1.png" />

                    <div class="s-home-m-btn1" @click="onShowModal('mobile')">
                        <i class="far fa-envelope"></i>&nbsp;마케팅 문의하기
                    </div>
                </a-col>
                <a-col :span="24" style="margin-top:20px;text-align:center">

                    <div><img style="width:80%" src="@/assets/images/v4-icon/mmacbook.png" /></div>

                </a-col>
                <a-col :span="24" style="margin-top:60px">
                    <div class="s-m-section2" style="width:100%;text-align:center">
                        <div><img style="width:70%;margin-top:100px;margin-bottom:41px" src="@/assets/images/v4-icon/mtitle2.png"></div>
                        <div style="widht:80%">

                            <a-input class="s-input2" v-model="keyword" @pressEnter="onSearch()" placeholder="키워드입력">
                                <div slot="suffix">
                                    <img src="@/assets/images/v4-icon/search.png" />
                                </div>
                            </a-input>

                        </div>
                    </div>
                </a-col>
                <a-col :span="24" style="margin-top:60px;margin-bottom:60px;text-align:center">
                    <div><img style="width:90%;margin-left:-10px" src="@/assets/images/v4-icon/mtitle3-1.png" /></div>
                </a-col>
                <a-col :span="24" style="padding-top:60px;padding-bottom:60px;text-align:center;background-color:#F8F8F8">
                    <div><img style="width:90%;margin-left:-20px" src="@/assets/images/v4-icon/mbg2-1.png" /></div>
                </a-col>
                <a-col :span="24" style="padding-top:60px;padding-bottom:30px;text-align:center;">
                    <div><img style="width:70%" src="@/assets/images/v4-icon/mtitle4.png" /></div>
                </a-col>
                <a-col :span="24">
                    <div style="margin:40px">
                        <LazyYoutubeVideo :src='videos[0].url' :preview-image-size="videos[0].previewImageSize" />
                        <div class="">
                            <div class="s-home-font1">
                                프롬더네일
                            </div>
                            <div class="s-m-home-font2">
                                제품에 맞는 효율적인 컨설팅으로<br />
                                적은 비용으로 최대 효과!<br />
                                매출 2배 성장, 스토어링크는 구세주
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="24" style="margin-bottom:20px">
                    <div style="margin:40px">
                        <LazyYoutubeVideo :src='videos[1].url' :preview-image-size="videos[1].previewImageSize" />
                        <div class="">
                            <div class="s-home-font1">
                                메디크로스
                            </div>
                            <div class="s-m-home-font2">
                                확실한 네이버스토어 상위노출로 매출 상승!<br />
                                ROAS 400% 달성, <br />
                                경쟁사는 이용 안했으면 좋겠어요..
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="24" style="padding-top:60px;padding-bottom:30px;text-align:center;background-color:#333333">
                    <div><img style="width:70%" src="@/assets/images/v4-icon/mtitle5.png" /></div>
                    <div @click="onDownload()"><img style="cursor:pointer;height:54px;margin-top:20px;margin-bottom:60px" src="@/assets/images/v4-icon/s-f-btn5.png"></div>
                </a-col>
                <a-col :span="24" style="padding-top:60px;padding-bottom:30px;text-align:left;">
                    <div><img style="width:210px;margin-left:30px" src="@/assets/images/v4-icon/mtitle6.png" /></div>
                    <div style="margin-top:20px">
                        <div style="display:flex;margin-left:20px">
                            <div class="s-home-font3"><a href="https://storelink.io/doc/storelink_privacypolicy.html" target="_blank">개인정보 처리방침</a></div>
                            <div class="s-home-font3"><a href="https://storelink.io/doc/storelink_termsofuse.html" target="_blank">이용약관</a></div>
                        </div>
                    </div>
                    <div><img style="width:288px;margin-top:30px;margin-bottom:60px;margin-left:30px" src="@/assets/images/v4-icon/mtitle7.png" /></div>
                </a-col>
            </a-row>
        </div>
    </a-layout-content>
    <a-layout-content v-else style="overflow-x:hidden">
        <div style="overflow-x:hidden">
            <a-row class="home" style="background-color:white">
                <a-col :span="12" :offset="6">
                    <div class="s-section1" style="text-align:center">
                        <div style="display:flex;margin-bottom:200px">
                            <div>
                                <div>
                                    <img style="width:400px;margin-top:-50px" src="@/assets/images/v4-icon/title2.png" />
                                </div>
                                <div class="s-home-btn1" @click="onShowModal('pc')">
                                    <i class="far fa-envelope"></i>&nbsp;마케팅 문의하기
                                </div>
                            </div>
                            <div style="margin-top:-100px;margin-left:-150px">
                                <div><img id="nuxt" style="width:750px" src="@/assets/images/v4-icon/macbook.png" /></div>
                            </div>
                            <div class="v-bar">
                                <video muted autoplay loop="" style="width:100%">
                                    <source src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2F21.03.31.mp4?alt=media&token=955cd776-0e32-47de-bc91-52884972b9a1">
                                </video>
                            </div>
                            <div style="margin-left:850px;margin-top:-70px;position:absolute;right:left">
                                <div class="slide-up">
                                    <div style="display:flex">
                                        <div class="dot"><img style="width:100%" src="@/assets/images/v4-icon/bullet-red.png" />
                                        </div>
                                        <div class="title">
                                            <number class="bold transition" :class="{scaleBig: scaleClass}" ref="number1" :from="numberFrom" :format="theFormat" animationPaused :to="numberTo" :duration="duration" easing="Power4.easeOut" />
                                        </div>
                                    </div>
                                    <div class="type"> Campaigns</div>
                                </div>

                            </div>
                            <div style="margin-left:950px;position:absolute;right:left;margin-top:30px">
                                <div class="slide-up">
                                    <div style="display:flex">
                                        <div class="dot"><img style="width:100%" src="@/assets/images/v4-icon/bullet-yellow.png" />
                                        </div>
                                        <div class="title">
                                            <number class="bold transition" :class="{scaleBig: scaleClass}" ref="number2" :from="numberFrom" :format="theFormat" animationPaused :to="numberTo2" :duration="duration2" easing="Power4.easeOut" />
                                        </div>
                                    </div>
                                    <div class="type"> Keyword</div>
                                </div>
                            </div>
                            <div style="margin-left:910px;position:absolute;right:left;margin-top:100px">
                                <div class="slide-up ">
                                    <div style="display:flex">
                                        <div class="dot"><img style="width:100%" src="@/assets/images/v4-icon/bullet-blue.png" />
                                        </div>
                                        <div class="title">
                                            <number class="bold transition" :class="{scaleBig: scaleClass}" ref="number3" :from="numberFrom" :format="theFormat" animationPaused :to="numberTo3" :duration="duration3" easing="Power4.easeOut" />
                                        </div>
                                    </div>
                                    <div class="type"> Users</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="24">
                    <div class="s-section2" style="width:100%;text-align:center">
                        <div><img style="width:439px;margin-top:100px;margin-bottom:41px" src="@/assets/images/v4-icon/title3.png"></div>
                        <div>

                            <a-input class="s-input1" style="width:360px" v-model="keyword" @pressEnter="onSearch()" placeholder="키워드입력">
                                <div slot="suffix">
                                    <img src="@/assets/images/v4-icon/search.png" />
                                </div>
                            </a-input>

                        </div>
                    </div>
                </a-col>
                <a-col :span="12" :offset="6">
                    <div class="s-section" style="background-color:white">
                        <div><img style="width:100%;margin-top:100px;margin-bottom:100px" src="@/assets/images/v4-icon/title8.png"></div>
                    </div>
                </a-col>
                <a-col :span="24" style="background-color:#F8F8F8">
                    <a-row>
                        <a-col :span="12" :offset="6">
                            <div class="s-section">
                                <div><img style="width:100%;margin-top:100px;margin-bottom:100px" src="@/assets/images/v4-icon/title5.png"></div>
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col :span="24">
                    <div class="s-section" style="background-color:white">
                        <div><img style="width:511px;margin-top:100px;margin-bottom:40px" src="@/assets/images/v4-icon/title6.png"></div>
                    </div>
                </a-col>
                <a-col :span="12" :offset="6">
                    <a-row style="margin-bottom:100px">
                        <a-col :span="12">
                            <div style="margin:10px">
                                <LazyYoutubeVideo :src='videos[0].url' :preview-image-size="videos[0].previewImageSize" />
                                <div class="">
                                    <div class="s-home-font1">
                                        프롬더네일
                                    </div>
                                    <div class="s-home-font2">
                                        제품에 맞는 효율적인 컨설팅으로<br />
                                        적은 비용으로 최대 효과!<br />
                                        매출 2배 성장, 스토어링크는 구세주
                                    </div>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="12">
                            <div style="margin:10px">
                                <LazyYoutubeVideo :src='videos[1].url' :preview-image-size="videos[1].previewImageSize" />
                                <div class="">
                                    <div class="s-home-font1">
                                        메디크로스
                                    </div>
                                    <div class="s-home-font2">
                                        확실한 네이버스토어 상위노출로 매출 상승!<br />
                                        ROAS 400% 달성, <br />
                                        경쟁사는 이용 안했으면 좋겠어요..
                                    </div>
                                </div>
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col :span="24">
                    <div class="s-section" style="margin-top:0px;background-color:#333333">
                        <div><img style="height:106px;margin-top:100px;margin-bottom:30px" src="@/assets/images/v4-icon/title7.png"></div>
                        <div @click="onDownload()"><img style="cursor:pointer;height:54px;margin-top:0px;margin-bottom:100px" src="@/assets/images/v4-icon/s-f-btn5.png"></div>
                    </div>
                </a-col>
                <a-col :span="16" :offset="4">
                    <a-row style="margin-bottom:58px;margin-top:68px">
                        <a-col :span="12">
                            <div style="margin:10px">
                                <div><img style="height:53px" src="@/assets/images/v4-icon/slogo2.png"></div>
                            </div>
                            <div style="margin-top:20px">
                                <div style="display:flex">
                                    <div class="s-home-font3"><a href="https://storelink.io/doc/storelink_privacypolicy.html" target="_blank">개인정보 처리방침</a></div>
                                    <div class="s-home-font3"><a href="https://storelink.io/doc/storelink_termsofuse.html" target="_blank">이용약관</a></div>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="12">
                            <div style="margin:10px">
                                <div><img style="height:110px" src="@/assets/images/v4-icon/slogo3.png"></div>
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
        </div>
    </a-layout-content>
    <contact-modal :visible="showFlag" :mode="mode" @closed="onCloseEvent()" @clicked="onClickEvent"></contact-modal>
</a-layout>
</template>

<script>
import VLazyImage from "v-lazy-image";
import firebase from "firebase";
import LazyYoutubeVideo from "vue-lazy-youtube-video";
import ContactModal from "../components/ContactModal/index";

import {
  isMobile,
  showError,
  showSuccess,
  getCurrentTimeNew
} from "../../views/components/fnc.js";

export default {
  components: {
    VLazyImage,
    LazyYoutubeVideo,
    ContactModal
  },
  data() {
    return {
      numberFrom: 0,
      numberTo: 10864,
      numberTo2: 111375,
      numberTo3: 10490,
      duration: 4,
      duration2: 5,
      duration3: 2,
      scaleClass: false,
      showFlag: false,
      imageWidth: null,
      imageHeight: null,
      imageMarginRight: null,
      keyword: "",
      width: 0,
      height: 0,
      videos: [
        {
          url: "https://www.youtube.com/embed/O3sLVPHBnbE",
          previewImageSize: "sddefault"
        },
        {
          url: "https://www.youtube.com/embed/Ukxa7TaeQTQ",
          previewImageSize: "sddefault"
        }
      ]
    };
  },
  computed: {
    checkLogin() {
      return (
        this.$store.state.AppActiveUser.email && firebase.auth().currentUser
      );
    },
    classObject: function() {
      return {
        f1: this.swidth == 1,
        f2: this.swidth == 2,
        f3: this.swidth == 3,
        f4: this.swidth == 4,
        f5: this.swidth == 5,
        f6: this.swidth == 6,
        f7: this.swidth == 7
      };
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      return _mobile;
    }
  },
  mounted() {
    this.scaleClass = false;

    if (!isMobile()) {
      this.$refs.number1.play();
      this.$refs.number2.play();
      this.$refs.number3.play();
    }

    if (this.checkLogin) {
      this.$store.dispatch("auth/refreshCache", {
        user: firebase.auth().currentUser,
        isReloadRequired: false
      });

      var d = getCurrentTimeNew();

      if (this.getUserKey && this.getEmail) {
        var uref = firebase.database().ref("user/" + this.getUserKey);

        var upData = {
          loggindttm: d
        };
        uref
          .update(upData)
          .then(function() {})
          .catch(function(error) {});
      }
    }
    // this.winWidth();
    if (this._mobile) {
      ChannelIO("boot", {
        pluginKey: "9080c269-a81a-4d54-8e67-ff6c7418a544",
        customLauncherSelector: ".custom-button-1",
        hideDefaultLauncher: true,
        mobileOpenUI: "iframe"
      });
    } else {
      ChannelIO("boot", {
        pluginKey: "9080c269-a81a-4d54-8e67-ff6c7418a544",
        customLauncherSelector: ".custom-button-1",
        hideDefaultLauncher: false,
        mobileOpenUI: "iframe"
      });
    }
    window.kakaoPixel("845593645083740063").pageView();
    fbq("track", "PageView");
  },
  methods: {
    onShowModal(type) {
      this.mode = type;
      this.showFlag = true;
    },
    theFormat(number) {
      return number.toFixed(0);
    },
    onClickEvent(e) {
      //TO-DO
    },
    onCloseEvent() {
      this.showFlag = false;
    },
    onSearch() {
      let routeData = this.$router.resolve({
        name: "fridaysearch",
        query: {
          cid: this.keyword
        }
      });
      window.open(routeData.href, "_self");
    },
    onDownload() {
      var self = this;
      if (!this.checkLogin) {
        var self = this;
        showSuccess({
          notify: self.$vs.notify,
          msg: "로그인하시면 다운로드 받을 수 있습니다."
        });
      } else {
        window.open(
          "https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2F(20.09.24)%E1%84%89%E1%85%B3%E1%84%90%E1%85%A9%E1%84%8B%E1%85%A5%E1%84%85%E1%85%B5%E1%86%BC%E1%84%8F%E1%85%B3_%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5_%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%B1%E1%84%82%E1%85%A9%E1%84%8E%E1%85%AE%E1%86%AF.pdf?alt=media&token=34a5758a-a2bf-48ee-997a-f0b0d2948094",
          "_blank"
        );
      }
    }
  }
};
</script>

<style>
.s-section1 {
  /* margin-left: 20px;
  margin-right: 20px; */
  width: 100%;
  margin-top: 240px;
}

.s-section {
  width: 100%;
  text-align: center;
}

.v-bar {
  position: absolute;
  z-index: 0;
  float: left;
  left: 0;
  margin-left: 492px;
  margin-top: -8px;
  width: 408px;
}

.mvbar {
  position: absolute;
  z-index: 99;
  margin-top: -158px;
  right: 0;

  width: 220px;
}

.s-home-btn1 {
  width: 180px;
  height: 54px;

  background: #000000;
  border-radius: 5px;
  margin-top: 30px;

  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #ffffff;
  padding-top: 14px;
  text-align: center;
  cursor: pointer;
}

.s-home-m-btn1 {
  width: 180px;
  height: 54px;

  background: #000000;
  border-radius: 5px;

  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.03em;
  margin: 0 auto;
  margin-top: 20px;
  color: #ffffff;
  padding-top: 14px;
  text-align: center;
  cursor: pointer;
}

.s-section2 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fsection-bg1.png?alt=media&token=a035d060-9958-4b81-9933-a5709da9cb5d");
  height: 417px;
  width: 100vw;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.s-m-section2 {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fmbg1.png?alt=media&token=520563db-c745-4de6-9e53-c52e4a3c640b");
  height: 417px;
  width: 100vw;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.s-home-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #0264fb;
  margin-top: 20px;
  margin-bottom: 10px;
}

.s-home-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.03em;

  color: #333333;
}

.s-m-home-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.03em;

  color: #333333;
}

.s-home-font3 > a {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.03em;
  text-decoration-line: underline;
  text-align: left;

  color: #828282;
  padding: 10px;
}

@keyframes slide-up {
  from {
    padding-top: 100px;
    opacity: 0.25;
    line-height: 80px;
  }

  to {
    padding-top: 0;
    opacity: 1;
    line-height: 25px;
  }
}

@-moz-keyframes slide-up {
  from {
    padding-top: 100px;
    opacity: 0.25;
    line-height: 80px;
  }

  to {
    padding-top: 0;
    opacity: 1;
    line-height: 25px;
  }
}

@-webkit-keyframes slide-up {
  0% {
    padding-top: 100px;
    opacity: 0.25;
    line-height: 80px;
  }

  100% {
    padding-top: 0;
    opacity: 1;
    line-height: 25px;
  }
}
@-o-keyframes slide-up {
  from {
    padding-top: 100px;
    opacity: 0.25;
    line-height: 80px;
  }

  to {
    padding-top: 0;
    opacity: 1;
    line-height: 25px;
  }
}

.slide-up {
  animation: slide-up 1s;
  -webkit-animation: slide-up 1s;
}

.number-count {
  font-family: "Muli";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.03em;

  color: #000000;
}

.slide-up .dot {
  margin-top: -16px;
  width: 40px;
  height: 40px;
}

.slide-up .title {
  margin-left: -10px;
  margin-top: -12px;
}

.slide-up .type {
  font-family: "Muli";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #828282;
  margin-left: 35px;
  margin-top: -10px;
}
</style>
